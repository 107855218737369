<template>
  <div class="bg bg3">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
    <!--      <img class="title&#45;&#45;img" :src="menu1" alt="">-->
    <!--      <div class="class&#45;&#45;con__title">大师作品回顾：</div>-->
    <!--      <div class="class&#45;&#45;container">-->
    <!--        <a-row :gutter="24" style="height: 100%">-->
    <!--          <a-col :span="5">-->
    <!--            <div style="font-size: 24px;color:#2B3654;margin-bottom: 39px">截止到今天，本学期一共欣赏了<span-->
    <!--                style="color:#FC795B;">{{ courseMasterWorkList.kngSize }}副</span>大师作品！-->
    <!--            </div>-->
    <!--            <div style="margin-bottom: 39px">作品名称：{{ courseMasterWorkList.worksName }}</div>-->
    <!--            <div style="margin-bottom: 39px">作者：{{ courseMasterWorkList.masterUser }}</div>-->
    <!--&lt;!&ndash;            <div v-if="courseMasterWorkList.worksDesc">作品描述：{{ courseMasterWorkList.worksDesc }}</div>&ndash;&gt;-->
    <!--            <div class="master">-->
    <!--              大师作品-->
    <!--            </div>-->
    <!--          </a-col>-->
    <!--          <a-col :span="18" :offset="1">-->
    <!--            <div style="display: flex;align-items: center;flex-direction: column">-->
    <!--&lt;!&ndash;              <div style="color: #2B3654;font-size: 40px">代表作品</div>&ndash;&gt;-->
    <!--              <div class="class&#45;&#45;container__img">-->
    <!--                <img :src="courseMasterWorkList.imgUrl" alt="">-->
    <!--              </div>-->
    <!--            </div>-->

    <!--          </a-col>-->
    <!--        </a-row>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import menu1 from "@/assets/image/conference/btn_grow.png"
import menu2 from "@/assets/image/conference/pic_grow.png"
import service from "@/utils/axios"
import {Row, Col} from 'ant-design-vue';

export default {
  name: "sixPage",
  components: {
    ClassBut,
    ARow: Row,
    ACol: Col,
  },
  data() {
    return {
      menu1: menu1,
      menu2: menu2,
      courseMasterWorkList: {}
    }
  },
  created() {
    if (this.$route.query.subjectName === '基础') {
      this.$router.replace({
        name: 'PageNinth',
        query: this.$route.query,
      })
    } else {
      this.gitApicourseMasterWork()
    }
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'PageNinth',
        query: this.$route.query,
      })
    },
    async gitApicourseMasterWork() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseMasterWork`, {
        classId: this.$route.query.classId,
      })
      console.log(res)
      this.courseMasterWorkList = res
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 0px 90px;
  box-sizing: border-box;
  color: #2B3654;
  font-size: 24px;
  height: calc(100% - 140px);
}

.class--container__img {
  position: absolute;
  right: -80px;
  top: -134px;
  height: calc(100% + 140px);
  width: 100%;
  //height: 650px;
  border-radius: 30px;
  border: 5px solid #F1E0DC;

  img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: contain;
  }
}

.master {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 72px;
  right: -100px;

  &::after {
    content: "";
    width: 20px;
    height: 23px;
    display: inline-block;
    background: url("~@/assets/image/conference/ic_sanjx@2x.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
  }

}
</style>
